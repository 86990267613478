var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "the-end" },
    [
      _vm.active
        ? _c("_type-writer", {
            attrs: { string: _vm.str },
            on: { finish: _vm.onFinish },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "end", style: { opacity: _vm.finished ? 1 : 0 } },
        [_vm._v("The End.")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }