var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading" }, [
    _c(
      "div",
      { staticClass: "desc" },
      [
        !_vm.ready ? _c("_loading-text") : _vm._e(),
        _vm.ready ? _c("_scroll-indicator") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }