var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "_type-writer" },
    [
      _vm.debug
        ? _c("_foo", {
            ref: "typed",
            attrs: { string: _vm.string },
            on: {
              preStringTyped: _vm.onPreStringTyped,
              onComplete: _vm.onComplete,
            },
          })
        : _c(
            "vue-typed-js",
            {
              ref: "typed",
              attrs: { strings: [_vm.string], loop: false, typeSpeed: 120 },
              on: {
                preStringTyped: _vm.onPreStringTyped,
                onComplete: _vm.onComplete,
              },
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("span", { staticClass: "typing" }),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }