var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "to-forever" },
    [
      _vm.active
        ? _c("_type-writer", {
            attrs: { string: _vm.str },
            on: { finish: _vm.onFinish },
          })
        : _vm._e(),
      _vm.finished ? _c("_scroll-indicator") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }