<template lang="pug">
.loading
  .desc
    _loading-text(v-if="!ready")

    _scroll-indicator(v-if="ready")
</div>
</template>

<script>
import { loadAllAssets } from "../loader"
import { imgs } from "../loader"

export default {
  data() {
    return {
      text: "加载中",
      suffix: "",
      ready: false,
      loadingGif: imgs.loadingGif,
    }
  },

  mounted() {
    const start = Date.now()
    this.i = 0

    setInterval(() => {
      this.suffix = ".".repeat(this.i % 6)
      this.i++
    }, 300)

    loadAllAssets()
      .then(() => {
        this.ready = true
        this.$emit("ready")
      })
      .catch((err) => {
        console.error(err)
        alert("加载失败了，重新打开试一下思密达~" + err.message)
      })
  },
}
</script>

<style lang="stylus" scoped>
.loading
  height: 100vh
  display: flex
  flex-direction: column
  color: white

.desc
  margin-top: auto
  margin-bottom: 20px
  display: flex
  align-items: center
  padding-right: 20px

  > span
    margin-left: 20px
    margin-right: 20px
    width: 80px
</style>
