<template lang="pug">
.index
  _type-writer(
    v-if="active"
    :string="str"
    @finish="onFinish"
  )

  _scroll-indicator(v-if="finished")
</template>

<script>
export default {
  props: {
    img: {
      type: String,
    },
  },

  data() {
    return {
      active: false,
      finished: false,
      str: _global.strings.index,
    }
  },

  mounted() {
    _bus.$on("indexActive", () => {
      this.active = true
    })
  },

  methods: {
    onFinish() {
      this.finished = true
      this.$emit("finish")
    },
  },
}
</script>

<style lang="stylus">
.index
  height: 100%
  box-sizing: border-box
  padding: 10px

  ._type-writer
    .typing
      background: auto
</style>