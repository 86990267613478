var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "_foo" }, [
    _c("span", [_vm._v("如下简介使用打字机，调试模式先关闭打字机效果")]),
    _c("pre", [_vm._v(_vm._s(_vm._f("format")(_vm.string)))]),
    _c("div", { staticClass: "typed-cursor" }),
    _c("div", { staticClass: "typing" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }