<template lang="pug">
._foo
  span 如下简介使用打字机，调试模式先关闭打字机效果
  pre {{ string | format }}

  .typed-cursor

  .typing
</template>

<script>
export default {
  props: {
    string: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.$emit("preStringTyped")

    setTimeout(() => {
      this.$emit("onComplete")
    }, 100)
  },

  filters: {
    format(str) {
      return str.replace(/\^\d+/g, "")
    }
  }
}
</script>

<style lang="stylus" scoped>
span
  font-size: 0.8em

pre
  border: solid 1px red
</style>
