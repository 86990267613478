var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "to-love" },
    [
      _vm.active
        ? _c("_type-writer", {
            attrs: { string: _vm.str },
            on: {
              finish: function ($event) {
                return _vm.$emit("finish")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }