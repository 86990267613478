<template lang="pug">
._music(
  @click="toggleActive"
  :data-inactive="!active"
)
  svg(
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
  )
    path(
      d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"
    )
    path(
      fill-rule="evenodd"
      d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"
    )
    path(d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z")
</template>

<script>
const playProps = new createjs.PlayPropsConfig().set({
  volume: 0.3,
})

export default {
  sound: null,

  data() {
    return {
      active: true,
    }
  },

  mounted() {
    this.sound = createjs.Sound.createInstance("love")
    this.sound.play(playProps)
  },

  methods: {
    toggleActive() {
      this.active = !this.active

      if (this.active) {
        this.sound.play(playProps)
      } else {
        this.sound.paused = true
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@keyframes rotate
  from
    transform: rotate(0)

  to
    transform: rotate(360deg)

._music
  width: 25px
  height: 25px
  position: relative
  animation: rotate 2.5s linear infinite

  &[data-inactive]
    color: #cdd2d7
    animation: none

  > svg
    width: 100%

  &[data-inactive]::after
    content: ''
    position: absolute
    width: 130%
    height: 2px
    background: #cdd2d7
    left: 0
    top: 0
    transform: translateX(5px) rotate(45deg)
    transform-origin: 0 0
</style>