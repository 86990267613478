<template lang="pug">
.to-know
  _type-writer(
    v-if="active"
    :string="str"
    @finish="onFinish"
  )

  _scroll-indicator(v-if="finished")
</template>

<script>
export default {
  data() {
    return {
      active: false,
      finished: false,
      str: _global.strings.toKnow,
    }
  },

  mounted() {
    _bus.$on("toKnowActive", () => {
      this.active = true
    })
  },

  methods: {
    onFinish() {
      this.finished = true
      this.$emit("finish")
    },
  },
}
</script>

<style lang="stylus">
.to-know
  ._type-writer
    .content
      margin-top: 50px
      margin-left: auto

      .typing
        background: none
</style>
