<template lang="pug">
.to-forever
  _type-writer(
    v-if="active"
    :string="str"
    @finish="onFinish"
  )

  _scroll-indicator(v-if="finished")
</template>

<script>
export default {
  data() {
    return {
      active: false,
      finished: false,
      str: _global.strings.toForever,
    }
  },

  mounted() {
    _bus.$on("toForeverActive", () => {
      this.active = true
    })
  },

  methods: {
    onFinish() {
      setTimeout(() => {
        this.$emit("finish")
        this.finished = true
      })
    },
  },
}
</script>

<style lang="stylus">
.to-forever
  height: 100%
  display: flex
  flex-direction: column

  ._type-writer
    margin-top: auto
    margin-bottom: 20px

    .typing
      background: none
</style>
