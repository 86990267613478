import "normalize.css"
import * as util from "./util"
import Vue from "vue"
import "swiper/swiper-bundle.css"
import VueAwesomeSwiper from "vue-awesome-swiper"
import VueTypedJs from "vue-typed-js"
import strings from "./strings.json"
import "vue-typed-js/dist/vue-typed-js.css"

// => Global Variables
window._util = util
// window._sentry = sentryReportError
window._global = {
  isDev: location.port !== "",
  apiEnv: "dev",
  strings,
}
window._bus = new Vue()
window._log = (msg) => {
  const p = document.createElement("p")
  p.innerText = msg
  document.querySelector("#logger").appendChild(p)
}

// => Init
// initSentry()

// => Vue Config
Vue.use(VueAwesomeSwiper)
Vue.use(VueTypedJs)

Vue.config.warnHandler = (msg, vm, trace) => {
  if (msg.startsWith("Invalid component name")) return
  console.error(msg, trace)
}

Vue.config.productionTip = false

// => Global components
const context = require.context("./components/_common/", true, /\.(vue|js)$/)

// key is the file path, e.g. `./_loading.vue`
context.keys().forEach((key) => {
  // dir, e.g. `./_imgs-picker/index.vue`
  if (key.lastIndexOf("/") !== 1) {
    if (key.indexOf("index.vue") !== -1) {
      const name = key.slice(2, key.lastIndexOf("/"))
      Vue.component(name, context(key).default)
    }
  } else {
    const dotIndex = key.lastIndexOf(".")
    const name = key.slice(2, dotIndex)
    Vue.component(name, context(key).default)
  }
})
