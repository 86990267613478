var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.showMusic ? _c("_music") : _vm._e(),
      _c(
        "swiper",
        {
          ref: "mySwiper",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOptions },
          on: { slideChange: _vm.onSlideChange },
        },
        [
          _c(
            "swiper-slide",
            [
              _c(
                "_wrapper",
                { attrs: { bgImg: _vm.imgs.loading } },
                [_c("loading", { on: { ready: _vm.onReady } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            {
              attrs: {
                "data-pause": "",
                "data-noprev": "",
                "data-name": "index",
              },
            },
            [
              _c(
                "_wrapper",
                { attrs: { bgImg: _vm.imgs.index, mask: "rgba(0,0,0,0.15)" } },
                [_c("index", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            { attrs: { "data-pause": "", "data-name": "toKnow" } },
            [
              _c(
                "_wrapper",
                { attrs: { bgImg: _vm.imgs.toKnow, mask: "rgba(0,0,0,0.15)" } },
                [_c("to-know", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toKnow01 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toKnow02 } })],
            1
          ),
          _c(
            "swiper-slide",
            { attrs: { "data-pause": "", "data-name": "toLove" } },
            [
              _c(
                "_wrapper",
                { attrs: { bgImg: _vm.imgs.toLove } },
                [_c("to-love", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toLove01 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toLove02 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toLove03 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toLove04 } })],
            1
          ),
          _c(
            "swiper-slide",
            { attrs: { "data-pause": "", "data-name": "beTogether" } },
            [
              _c(
                "_wrapper",
                {
                  attrs: {
                    bgImg: _vm.imgs.beTogether,
                    mask: "rgba(0,0,0,0.15)",
                  },
                },
                [_c("be-together", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.beTogether01 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.beTogether02 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.beTogether03 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.beTogether04 } })],
            1
          ),
          _c(
            "swiper-slide",
            { attrs: { "data-pause": "", "data-name": "toForever" } },
            [
              _c(
                "_wrapper",
                {
                  attrs: {
                    bgImg: _vm.imgs.toForever,
                    mask: "rgba(0,0,0,0.15)",
                  },
                },
                [_c("to-forever", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toForever01 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toForever02 } })],
            1
          ),
          _c(
            "swiper-slide",
            [_c("_wrapper", { attrs: { bgImg: _vm.imgs.toForever03 } })],
            1
          ),
          _c(
            "swiper-slide",
            { attrs: { "data-pause": "", "data-name": "theEnd" } },
            [
              _c(
                "_wrapper",
                { attrs: { bgImg: _vm.imgs.theEnd, mask: "rgba(0,0,0,0.15)" } },
                [_c("the-end", { on: { finish: _vm.onFinish } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }