var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "_wrapper", style: _vm.style },
    [
      _vm.mask !== ""
        ? _c("div", {
            staticClass: "_wrapper__mask",
            style: { backgroundColor: _vm.mask },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }