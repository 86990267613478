var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "_loading" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 50 50" },
        attrs: {
          version: "1.1",
          id: "Layer_1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: "24px",
          height: "30px",
          viewBox: "0 0 24 30",
          "xml:space": "preserve",
        },
      },
      [
        _c(
          "rect",
          {
            attrs: { x: "0", y: "0", width: "4", height: "10", fill: _vm.fill },
          },
          [
            _c("animateTransform", {
              attrs: {
                attributeType: "xml",
                attributeName: "transform",
                type: "translate",
                values: "0 0; 0 20; 0 0",
                begin: "0",
                dur: "0.6s",
                repeatCount: "indefinite",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "rect",
          {
            attrs: {
              x: "10",
              y: "0",
              width: "4",
              height: "10",
              fill: _vm.fill,
            },
          },
          [
            _c("animateTransform", {
              attrs: {
                attributeType: "xml",
                attributeName: "transform",
                type: "translate",
                values: "0 0; 0 20; 0 0",
                begin: "0.2s",
                dur: "0.6s",
                repeatCount: "indefinite",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "rect",
          {
            attrs: {
              x: "20",
              y: "0",
              width: "4",
              height: "10",
              fill: _vm.fill,
            },
          },
          [
            _c("animateTransform", {
              attrs: {
                attributeType: "xml",
                attributeName: "transform",
                type: "translate",
                values: "0 0; 0 20; 0 0",
                begin: "0.4s",
                dur: "0.6s",
                repeatCount: "indefinite",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }