var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "_music",
      attrs: { "data-inactive": !_vm.active },
      on: { click: _vm.toggleActive },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor",
            viewBox: "0 0 16 16",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z",
            },
          }),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              d: "M14 11V2h1v9h-1zM6 3v10H5V3h1z",
            },
          }),
          _c("path", {
            attrs: {
              d: "M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }