<template lang="pug">
._type-writer
  _foo(
    :string="string"
    ref="typed"
    v-if="debug"
    @preStringTyped="onPreStringTyped"
    @onComplete="onComplete"
  )

  vue-typed-js(
    v-else
    ref="typed"
    :strings="[string]"
    :loop="false"
    :typeSpeed="120"
    @preStringTyped="onPreStringTyped"
    @onComplete="onComplete"
  )
    .content
      span.typing
</template>

<script>
const playProps = new createjs.PlayPropsConfig().set({
  volume: 0.1,
})

function playSound() {
  createjs.Sound.play("keypress", playProps)
}

export default {
  props: {
    debug: {
      type: Boolean,
      default: false,
    },
    string: {
      type: String,
      required: true,
    },
  },

  methods: {
    onPreStringTyped() {
      const callback = function (mutations, observer) {
        playSound()
      }

      this.observer = new MutationObserver(callback)

      this.observer.observe(this.$refs.typed.$el.querySelector(".typing"), {
        childList: true,
        subtree: true,
      })
    },

    // 移除光标
    // `showCursor` 选项只会影响初始配置
    // 如果一开始是 true，后面是 false，那么不会移除光标
    onComplete() {
      this.observer.disconnect()
      this.$refs.typed.$el.querySelector(".typed-cursor").remove()
      this.$emit("finish")
    },
  },
}
</script>

<style lang="stylus" scoped>
.content
  text-align: left
  font-size: 22px
  line-height: 30px
  font-family: myfont
  color: rgba(255, 255, 255, 1)

.typing
  white-space: pre
</style>