<template lang="pug">
._scroll-indicator
  img(:src="src")
  .content
</template>

<script>
import { imgs } from "../../loader"

export default {
  data() {
    return {
      src: imgs.down,
    }
  },
}
</script>

<style lang="stylus">
._scroll-indicator
  position: fixed
  bottom: 20px
  right: 15px

  > img
    animation: bounce 1s infinite
    width: 25px

@keyframes bounce
  0%
    transform: translateY(0px)

  50%
    transform: translateY(8px)

  100%
    transform: translateY(0px)
</style>