<template lang="pug">
.to-love
  _type-writer(
    v-if="active"
    :string="str"
    @finish="$emit('finish')"
  )
</template>

<script>
export default {
  data() {
    return {
      active: false,
      str: _global.strings.toLove,
    }
  },

  mounted() {
    _bus.$on("toLoveActive", () => {
      this.active = true
    })
  },
}
</script>

<style lang="stylus">
.to-love
  height: 100vh
  display: flex
  flex-direction: column

  ._type-writer
    margin-top: auto
    margin-bottom: 40px

    .content
      margin-left: auto
      color: #333
</style>
