<template lang="pug">
._wrapper(:style="style")
  ._wrapper__mask(
    v-if="mask !== ''"
    :style="{ backgroundColor: mask }"
  )
  slot
</template>

<script>
export default {
  props: {
    bgImg: {
      type: String,
      required: true,
    },

    mask: {
      // mask color
      type: String,
      default: "",
    },
  },

  computed: {
    style() {
      return {
        backgroundImage: `url('${this.bgImg}')`,
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
._wrapper
  height: var(--app-height)
  padding: 10px
  box-sizing: border-box
  background-size: cover
  background-position: 50% 50%
  position: relative
  transform: translate3d(0, 0, 0)

._wrapper__mask
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: -1
</style>
