// 字体
import myfont from "../assets/font/ZhongQiChenWeiXunYingBiXingShu-2.ttf.subset.ttf"

// 图片
import loading from "../assets/img/loading.jpg"
import down from "../assets/img/down.svg"

import index from "../assets/img/index.jpg"

import toLove from "../assets/img/toLove.jpeg"
import toLove01 from "../assets/img/toLove01.jpeg"
import toLove02 from "../assets/img/toLove02.jpeg"
import toLove03 from "../assets/img/toLove03.jpeg"
import toLove04 from "../assets/img/toLove04.jpeg"

import toKnow from "../assets/img/toKnow.jpeg"
import toKnow01 from "../assets/img/toKnow01.jpeg"
import toKnow02 from "../assets/img/toKnow02.jpeg"

import beTogether from "../assets/img/beTogether.jpeg"
import beTogether01 from "../assets/img/beTogether01.jpeg"
import beTogether02 from "../assets/img/beTogether02.jpeg"
import beTogether03 from "../assets/img/beTogether03.jpeg"
import beTogether04 from "../assets/img/beTogether04.jpeg"

import toForever from "../assets/img/toForever.jpeg"
import toForever01 from "../assets/img/toForever01.jpeg"
import toForever02 from "../assets/img/toForever02.jpeg"
import toForever03 from "../assets/img/toForever03.jpeg"

import theEnd from "../assets/img/theEnd.jpeg"

// 声音
import keypress from "../assets/mp3/key.mp3"
import love from "../assets/mp3/love.mp3"

const loadFont = ([name, src]) => {
  const font = new FontFace(name, `url('${src}')`, {
    style: "normal",
    weight: "400",
  })

  return font.load().then(() => {
    document.fonts.add(font)
  })
}

const loadImg = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = resolve
    img.onerror = (err) => {
      // alert("图片加载失败了" + src + err.message)
      reject()
    }
  })
}

const audioQueue = []

createjs.Sound.on("fileload", (evt) => {
  const item = audioQueue.find((i) => i.id === evt.id)
  item.resolve()
})

const loadAudio = ([id, src]) => {
  return new Promise((resolve, reject) => {
    createjs.Sound.registerSound({ id, src })

    audioQueue.push({
      id,
      resolve,
    })

    // const audio = new Audio()
    // audio.oncanplaythrough = resolve
    // audio.onerror = reject
    // audio.src = src
    // audio.load()

    // 在微信中要特别处理
    // document.addEventListener(
    //   "WeixinJSBridgeReady",
    //   () => {
    //     WeixinJSBridge.invoke("getNetworkType", {}, () => {
    //       // audio.load()
    //       // createjs.Sound.registerSound({ id, src })
    //     })
    //   },
    //   false
    // )
  })
}

export const imgs = {
  loading,
  down,

  index,

  toKnow,
  toKnow01,
  toKnow02,

  toLove,
  toLove01,
  toLove02,
  toLove03,
  toLove04,

  beTogether,
  beTogether01,
  beTogether02,
  beTogether03,
  beTogether04,

  toForever,
  toForever01,
  toForever02,
  toForever03,

  theEnd,
}

export const fonts = {
  myfont,
}

export const mp3 = {
  keypress,
  love,
}

export const loadAllAssets = () => {
  return Promise.all([
    ...Object.values(imgs).map(loadImg),
    ...Object.entries(mp3).map(loadAudio),
    ...Object.entries(fonts).map(loadFont),
  ])
}
