<template lang="pug">
.be-together
  _type-writer(
    v-if="active"
    :string="str"
    @finish="onFinish"
  )

  _scroll-indicator(v-if="finished")
</template>

<script>
export default {
  data() {
    return {
      active: false,
      finished: false,
      str: _global.strings.beTogether,
    }
  },

  mounted() {
    _bus.$on("beTogetherActive", () => {
      this.active = true
    })
  },

  methods: {
    onFinish() {
      setTimeout(() => {
        this.finished = true
        this.$emit("finish")
      }, 500)
    },
  },
}
</script>

<style lang="stylus">
.be-together
  display: flex
  height: 100%
  flex-direction: column

  ._type-writer
    margin-top: auto
    margin-bottom: 40px

    .typing
      background: none
</style>
