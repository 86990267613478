<template lang="pug">
.the-end
  _type-writer(
    v-if="active"
    :string="str"
    @finish="onFinish"
  )

  .end(:style="{ opacity: finished ? 1 : 0 }") The End.
</template>

<script>
export default {
  data() {
    return {
      active: false,
      finished: false,
      str: _global.strings.theEnd,
    }
  },

  mounted() {
    _bus.$on("theEndActive", () => {
      this.active = true
    })
  },

  methods: {
    onFinish() {
      this.finished = true
      this.$emit("finish")
    },
  },
}
</script>

<style lang="stylus">
.the-end
  height: 100%
  display: flex
  flex-direction: column

  ._type-writer
    margin-top: auto
    margin-bottom: 50px

    .typing
      background: none

  .end
    position: fixed
    bottom: 20px
    right: 10px
    color: white
    text-shadow: 0 0 5px white
    transition: opacity 3s linear
</style>
