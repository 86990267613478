<template lang="pug">
.home
  _music(v-if="showMusic")

  swiper.swiper(
    ref="mySwiper"
    :options="swiperOptions"
    @slideChange="onSlideChange"
  )
    swiper-slide
      _wrapper(:bgImg="imgs.loading")
        loading(@ready="onReady")

    //- data-pause: 第一次进入的时候禁止上滑和下滑
    //- 直到后续触发 finish 事件
    //- data-noprev: 进入以后不允许上滑

    //- 首页
    swiper-slide(
      data-pause
      data-noprev
      data-name="index"
    )
      _wrapper(
        :bgImg="imgs.index"
        mask="rgba(0,0,0,0.15)"
      )
        index(@finish="onFinish")

    //- 相识
    swiper-slide(
      data-pause
      data-name="toKnow"
    )
      _wrapper(
        :bgImg="imgs.toKnow"
        mask="rgba(0,0,0,0.15)"
      )
        to-know(@finish="onFinish")

    swiper-slide
      _wrapper(:bgImg="imgs.toKnow01")

    swiper-slide
      _wrapper(:bgImg="imgs.toKnow02")

    //- 相爱
    swiper-slide(
      data-pause
      data-name="toLove"
    )
      _wrapper(:bgImg="imgs.toLove")
        to-love(@finish="onFinish")

    swiper-slide
      _wrapper(:bgImg="imgs.toLove01")
      //- 再美的西藏也没有你美呀

    swiper-slide
      _wrapper(:bgImg="imgs.toLove02")
      //- 我去芜湖找你

    swiper-slide
      _wrapper(:bgImg="imgs.toLove03")
      //- 你来武汉看我

    swiper-slide
      _wrapper(:bgImg="imgs.toLove04")
      //- 距离从来不会改变我对你的心意

    //- 携手
    swiper-slide(
      data-pause
      data-name="beTogether"
    )
      _wrapper(
        :bgImg="imgs.beTogether"
        mask="rgba(0,0,0,0.15)"
      )
        be-together(@finish="onFinish")

    swiper-slide
      _wrapper(:bgImg="imgs.beTogether01")
        //- 我们租了自己的小房子。

    swiper-slide
      _wrapper(:bgImg="imgs.beTogether02")
        //- 把你培训成”软件工程师“👩‍💻

    swiper-slide
      _wrapper(:bgImg="imgs.beTogether03")
        //- 一起去走遍世界

    swiper-slide
      _wrapper(:bgImg="imgs.beTogether04")
        //- 有了我们的第一个”孩子“，可可

    //- 终生
    swiper-slide(
      data-pause
      data-name="toForever"
    )
      _wrapper(
        :bgImg="imgs.toForever"
        mask="rgba(0,0,0,0.15)"
      )
        to-forever(@finish="onFinish")

    swiper-slide
      _wrapper(:bgImg="imgs.toForever01")
        //- 5.20 日排队领证。

    swiper-slide
      _wrapper(:bgImg="imgs.toForever02")
        //- 去宁波拍婚纱照。

    swiper-slide
      _wrapper(:bgImg="imgs.toForever03")
      //- 带上可可拍全家福。

    //- 结尾
    swiper-slide(
      data-pause
      data-name="theEnd"
    )
      _wrapper(
        :bgImg="imgs.theEnd"
        mask="rgba(0,0,0,0.15)"
      )
        the-end(@finish="onFinish")
</template>

<script>
import { imgs } from "../loader"
import Loading from "./loading"
import Index from "./index"
import ToKnow from "./to-know"
import ToLove from "./to-love"
import BeTogether from "./be-together"
import ToForever from "./to-forever"
import TheEnd from "./the-end"

export default {
  visited: null,

  data() {
    return {
      showMusic: false,
      imgs,
      ready: false,
      swiperOptions: {
        direction: "vertical",
      },
    }
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },

  mounted() {
    this.visited = {}
    this.swiper.allowSlideNext = false
    window.addEventListener("resize", this.appHeight)
    this.appHeight()
  },

  methods: {
    appHeight() {
      document
        .querySelector(":root")
        .style.setProperty("--app-height", `${window.innerHeight}px`)
    },

    onFinish() {
      this.swiper.allowSlideNext = true

      // 首页
      if (this.swiper.activeIndex === 1) {
        this.showMusic = true
      }

      const slide = this.swiper.slides[this.swiper.activeIndex]
      if (slide.dataset.noprev == null) {
        this.swiper.allowSlidePrev = true
      }
    },

    onReady() {
      this.ready = true
      this.swiper.allowSlideNext = true
    },

    onSlideChange() {
      const activeIndex = this.swiper.activeIndex
      const slide = this.swiper.slides[activeIndex]

      // reset
      this.swiper.allowSlideNext = true
      this.swiper.allowSlidePrev = true

      // 第一次进入
      if (slide.dataset.pause != null && this.visited[activeIndex] == null) {
        this.visited[activeIndex] = true

        if (slide.dataset.pause != null) {
          this.swiper.allowSlideNext = false
          this.swiper.allowSlidePrev = false
        }

        if (slide.dataset.name != null) {
          _bus.$emit(slide.dataset.name + "Active")
        }
      }

      if (slide.dataset.noprev != null) {
        this.swiper.allowSlidePrev = false
      }
    },
  },

  components: {
    Loading,
    Index,
    ToKnow,
    ToLove,
    BeTogether,
    ToForever,
    TheEnd,
  },
}
</script>

<style lang="stylus" scoped>
.swiper
  height: var(--app-height)

.home
  position: relative

._music
  position: absolute
  top: 20px
  z-index: 99
  right: 20px
</style>

<style lang="stylus">
:root
  --app-height: 100vh

html
  font-family: PingFang SC
  font-size: 16px

body
  margin: 0

.swiper-slide
  transform: translate3d(0, 0, 0)
  overflow: hidden
</style>
